import gql from 'graphql-tag';

const FRAGMENT_GUARANTOR_INFO = gql`
  fragment fragmentQueryGuarantorInfo on Contact {
    id
    contactType
    relationship
    firstName
    middleName
    lastName
    title
    dateOfBirth
    countryTerritoryId
    countryTerritory
    cityTown
    cityTownId
    addressLine1
    addressLine2
    postcode
    mobilePhoneAreaCode
    mobilePhoneNumber
    homePhoneAreaCode
    homePhoneNumber
    contactEmail
    contactWithoutPermission
  }
`;

const FRAGMENT_STUDENT_INFO = gql`
  fragment UserFragmentQueryStudentInfo on Student {
    id
    originId
    email
    gender
    firstName
    middleName
    lastName
    preferredName
    preferredGenderPronoun
    gender
    communicationPreferences
    preferredLanguage
    preferredLanguageFull
    profilePhotoFileUuid
    profileCompletion {
      totalPoint
    }
    contact {
      guarantorContacts {
        ...fragmentQueryGuarantorInfo
      }
      emergencyContacts {
        ...fragmentQueryGuarantorInfo
      }
    }
    generalInfo {
      basic {
        firstName
        middleName
        lastName
        fullName
        title
        dateOfBirth
        gender
        nationality
      }
      education {
        degreeClassification
        destinationUniversity
        major
        universityCardId
        yearOfStudy
      }
      contact {
        countryTerritory
        countryTerritoryId
        cityTownId
        cityTown
        addressLine1
        addressLine2
        postcode
        contactEmail
        homePhone
        homePhoneAreaCode
        homePhoneNumber
        mobilePhone
        mobilePhoneNumber
        mobilePhoneAreaCode
      }
    }
    cards {
      id
      name
      email
      type
      expireYear
      expireMonth
      cardBrand
      last4
      funding
      platformAccount {
        id
        externalId
        name
        currency
        publishableKey
        originId
      }
      bookings {
        id
        tenancyOption {
          startDate
          endDate
        }
        roomAllocation {
          bedName
        }
        property {
          id
          name
        }
      }
      originId
    }
    cardVaultStatus
  }
  ${FRAGMENT_GUARANTOR_INFO}
`;

const FRAGMENT_SERVICE_SELECTION = gql`
  fragment fragmentServiceSelection on ServiceSelection {
    id
    serviceId
    serviceOptionId
    serviceName
    serviceType
    serviceOptionName
  }
`;

export const GET_PORTAL_CONFIG = gql`
  query SP_GetPortalConfig {
    getStudentPortalConfig {
      host
      name
      topLandlordId
      fileServiceHost
      propertyWithEnabledAcademicYear {
        id
        name
        imageName
        city {
          id
          name
        }
        country {
          id
          name
        }
        contactAreaCode
        contactPhoneNumber
        contactEmailAddress
      }
    }
  }
`;

export const REGISTER_STUDENT_USER = gql`
  mutation SP_RegisterStudentUser($input: registerStudentUserInput!) {
    registerStudentUser(input: $input) {
      authToken
      studentUser {
        studentInfo {
          originId
        }
      }
    }
  }
`;

export const PRE_REGISTER_STUDENT_USER = gql`
  mutation SP_PreRegisterStudentUser($input: preRegisterStudentUserInput!) {
    preRegisterStudentUser(input: $input) {
      validation
    }
  }
`;

export const STUDENT_LOGIN = gql`
  mutation SP_StudentLogin($email: String!, $password: String!, $topLandlordId: ID!) {
    studentLogin(email: $email, password: $password, topLandlordId: $topLandlordId) {
      authToken
    }
  }
`;

export const STUDENT_FORGOT_PASSWORD = gql`
  mutation SP_StudentForgotPassword($email: String!, $topLandlordId: ID!) {
    studentForgotPassword(email: $email, topLandlordId: $topLandlordId) {
      success
    }
  }
`;

export const RESET_STUDENT_USER_PASSWORD = gql`
  mutation SP_ResetStudentUserPassword($password: String!, $token: String!) {
    resetStudentUserPassword(password: $password, token: $token) {
      success
    }
  }
`;

export const QUERY_STUDENT_VIEWER = gql`
  query SP_QueryStudentViewer {
    studentViewer {
      id
      originId
      email
      emailVerified
      topLandlordId
      studentInfo {
        ...UserFragmentQueryStudentInfo
      }
    }
  }
  ${FRAGMENT_STUDENT_INFO}
`;

export const QUERY_STUDENT_ID = gql`
  query SP_QueryStudentId {
    studentViewer {
      studentInfo {
        originId
      }
    }
  }
`;

export const QUERY_STUDENT_GET_BOOKINGS = gql`
  query SP_QueryStudentGetBookings {
    studentViewer {
      id
      originId
      email
      emailVerified
      topLandlordId
      studentInfo {
        ...UserFragmentQueryStudentInfo
        bookings {
          id
          editable
          category
          tenancyLengthOption
          isRolling
          isRolledOver
          sourceRollingBooking {
            id
          }
          rolloverBookings {
            id
          }
          balanceInfo {
            walletBalance
          }
          bookingLink {
            originalBooking {
              id
              originId
            }
            replacementBooking {
              id
              originId
            }
            occupiedBooking {
              id
              originId
            }
            countDown
            type
            status
            sourceAcceptedAt
            sourceRejectedAt
            targetAcceptedAt
            targetRejectedAt
          }
          nominationPlan {
            id
            partner {
              id
              name
            }
            studentPayTo
          }
          checkInTaskStarted
          editInfo {
            createdAt
            updatedAt
          }
          amendmentDetails {
            sourceBookingId
            targetBookingId
            type
            status
            checkedIn
          }
          latestAmendmentDetail {
            targetBookingId
            status
            type
          }
          activeBookingsOnSameChain {
            id
            status
            countDown
            heldToTime
            checkInTaskStarted
            property {
              name
              city {
                name
              }
            }
            roomAllocation {
              bedName
              buildingName
            }
            tenancyOption {
              startDate
              endDate
            }
          }
          targetBookings {
            id
            status
            countDown
            heldToTime
            checkInTaskStarted
            property {
              name
              city {
                name
              }
            }
            roomAllocation {
              bedName
              buildingName
            }
            tenancyOption {
              startDate
              endDate
            }
            paymentItems {
              id
              name
              status
              totalAmount
            }
          }
          sourceBooking {
            id
            serviceSelections {
              ...fragmentServiceSelection
            }
          }
          status
          heldToTime
          countDown
          rentalTaxRateType
          priceForBillingCycle
          bookingForms {
            id
            name
            type
            filePath
            status
          }
          property {
            id
            name
            imageName
            billingCycle
            currency
            timezone
            contactAreaCode
            contactPhoneNumber
            contactEmailAddress
            city {
              name
            }
            country {
              name
            }
          }
          roomAllocation {
            bedName
            buildingName
          }
          roomType {
            name
          }
          tenancyOption {
            originalEndDate
            startDate
            endDate
            tenancyOptionId
            name
          }
          installmentPlans {
            id
            isDueBeforeBookingConfirmed
            dueDate
            amountDue
          }
          installment {
            name
            installmentId
          }
          documents {
            mandatory
            status
          }
          arrivalInformation {
            id
          }
          paymentItems {
            id
            name
            description
            isRefundable
            category
            currency
            paidAmount
            totalAmount
            status
            invoiceDatetime
            isDueBeforeBookingConfirmed
            dueDatetime
          }
          offerStatus
          serviceSelections {
            ...fragmentServiceSelection
          }
          partiallyPaidByBankTransferBeforeCheckIn
        }
      }
    }
  }
  ${FRAGMENT_STUDENT_INFO}
  ${FRAGMENT_SERVICE_SELECTION}
`;

export const MUTATION_TRIGGER_STUDENT_VERIFY_EMAIL = gql`
  mutation SP_MutationTriggerStudentVerifyEmail {
    triggerStudentVerifyEmail {
      success
    }
  }
`;

export const MUTATION_STUDENT_VERIFY_EMAIL = gql`
  mutation SP_StudentVerifyEmail($token: String!) {
    studentVerifyEmail(token: $token) {
      authToken
    }
  }
`;

export const MUTATION_RESET_PASSWORD_TOKEN = gql`
  mutation SP_ResetPasswordToken($token: String!) {
    verifyStudentResetPasswordToken(token: $token) {
      isValid
      isPasswordSet
      studentUserEmail
    }
  }
`;

export const MUTATION_EDUCATION_INFO = gql`
  mutation SP_UpdateStudentEducationInfo($input: studentUpdateEducationInfoInput!) {
    studentUpdateEducationInfo(input: $input) {
      ...UserFragmentQueryStudentInfo
    }
  }
  ${FRAGMENT_STUDENT_INFO}
`;

export const MUTATION_BASIC_INFO = gql`
  mutation SP_UpdateStudentBasicInfo($input: studentUpdateBasicInfoInput!) {
    studentUpdateBasicInfo(input: $input) {
      ...UserFragmentQueryStudentInfo
    }
  }
  ${FRAGMENT_STUDENT_INFO}
`;

export const ADD_EMERGENCY_CONTACT = gql`
  mutation SP_CreateEmergencyContact($input: createStudentEmergencyContactInput!) {
    studentCreateEmergencyContact(input: $input) {
      ...fragmentQueryGuarantorInfo
    }
  }
  ${FRAGMENT_GUARANTOR_INFO}
`;

export const EDIT_EMERGENCY_CONTACT = gql`
  mutation SP_UpdateEmergencyContactInfo($input: updateStudentEmergencyContactInput!) {
    studentUpdateEmergencyContact(input: $input) {
      ...fragmentQueryGuarantorInfo
    }
  }
  ${FRAGMENT_GUARANTOR_INFO}
`;

export const DELETE_EMERGENCY_CONTACT = gql`
  mutation SP_DeleteEmergencyContact($id: ID!) {
    studentDeleteEmergencyContact(id: $id) {
      deletedId
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation SP_StudentChangePassword($input: studentChangePasswordInput!) {
    studentChangePassword(input: $input) {
      success
    }
  }
`;

export const CHANGE_EMAIL = gql`
  mutation SP_StudentChangeEmail($input: studentTriggerChangeEmailInput!) {
    studentTriggerChangeEmail(input: $input) {
      success
    }
  }
`;

export const UPDATE_AVATAR = gql`
  mutation SP_UpdateAvatar($input: studentUpdateProfilePhotoInput!) {
    studentUpdateProfilePhoto(input: $input) {
      ...UserFragmentQueryStudentInfo
    }
  }
  ${FRAGMENT_STUDENT_INFO}
`;

export const CHANGE_EMAIL_VERIFY = gql`
  mutation SP_ChangeEmail($token: NonEmptyString!) {
    studentChangeEmail(token: $token) {
      success
    }
  }
`;

export const GET_LANGUAGES = gql`
  query getLanguages {
    getLanguages {
      languages {
        abbreviation
        region
        default
        language
      }
    }
  }
`;
